export const functions = {
	title: { pl: 'Funkcje aplikacji' },
	content: {
		pl: 'Aplikacja ma na celu udostępnienie użytkownikowi materiałów wspomagających złagodzenie lub wyeliminowanie dolegliwości bólowych. Funkcje aplikacji zostały dostosowane do codziennych potrzeb użytkownika.'
	},
	list: {
		schedule: {
			pl: `Możliwość synchronizacji z\xa0kalendarzem systemowym.`
		},
		strength: { pl: 'Układanie planu ćwiczeń według swoich potrzeb.' },
		notification: {
			pl: 'Powiadomienia push o\xa0nadchodzących ćwiczeniach.'
		}
	}
};
