import { initializeApp } from 'firebase/app';

const firebaseConfig = {
	apiKey: 'AIzaSyD2eYUDOOKHYEfdkt0ue33eTQRZK0Ab8CI',
	authDomain: 'nfzmojefizjo.firebaseapp.com',
	projectId: 'nfzmojefizjo',
	storageBucket: 'nfzmojefizjo.appspot.com',
	messagingSenderId: '80655262715',
	appId: '1:80655262715:web:b4ccf3f5c60ac3d660615e',
	measurementId: 'G-F47PLFKPW6'
};

// Initialize Firebase
export const firebaseApp = () => initializeApp(firebaseConfig);
