import { FirebaseStorage } from 'components/utilities/firebaseStorage';

export const LIST_ITEMS = [
	{
		className: 'sm:pb-4',
		href: 'https://www.nfz.gov.pl/',
		label: 'footer.nfz'
	},
	{
		className: 'sm:pb-4',
		href: null,
		onClick: FirebaseStorage.getDeclarationOfAvailability,
		label: 'footer.declaration'
	},
	{
		href: null,
		onClick: FirebaseStorage.getPrivacyPolicyFile,
		label: 'footer.privatePolicy'
	}
];
